import React, { useState, useEffect } from "react";

import { Radio, RadioGroup, FormControlLabel, Container } from "@mui/material";
import { isMobile } from "react-device-detect";

import Feedback from "./Feeback";

const MultipleChoiceQuestion = ({
  existingQuestion: question,
  showResult,
  setAnswers,
  answers,
}) => {
  const [answeredCorrectly, setAnsweredCorrectly] = useState(false);
  const [correctAnswerId, setCorrectAnswerId] = useState(null);

  // Find the answer for the curent question from the answers array
  let selectedAnswerId;
  answers.forEach((answer) => {
    if (answer.questionId === question.id) {
      selectedAnswerId = answer?.answer;
    }
  });

  // find the selected answer text from question.answers via the selectedAnswerId
  let selectedAnswerText;
  question.answers.forEach((answer) => {
    if (answer.id === selectedAnswerId) {
      selectedAnswerText = answer.text;
    }
  });

  // Keep track of the correct answer and if the user answered correctly
  useEffect(() => {
    const currentAnswer = answers.find(
      (answer) => answer.questionId === question.id
    );
    if (!currentAnswer) return;

    setAnsweredCorrectly(currentAnswer?.isCorrect);
    setCorrectAnswerId(currentAnswer?.correctAnswer);
  }, [answers]);

  const getAnswerStyle = (answerId) => {
    if (!showResult || !selectedAnswerId || !correctAnswerId) return {};

    if (selectedAnswerId === answerId) {
      return answerId === correctAnswerId
        ? { border: "1px solid green" }
        : { border: "1px solid red" };
    }
    return {};
  };

  return (
    <Container
      sx={{
        height: isMobile ? "100vh" : "450px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <RadioGroup
        aria-label="answer"
        name="selectedAnswer"
        defaultValue={selectedAnswerId ? selectedAnswerId : ""}
        disabled={showResult}
      >
        <div style={{ marginBottom: "20px", fontSize: "32px" }}>
          {question?.text}
        </div>
        {question.answers.map((answer) => (
          <FormControlLabel
            key={answer.id}
            value={answer.id}
            control={<Radio />}
            label={answer?.text}
            disabled={showResult}
            onClick={() => {
              if (!showResult) {
                setAnswers((prev) => [
                  ...prev.filter(
                    (prevAnswer) => prevAnswer.questionId !== question.id
                  ),
                  { questionId: question.id, answer: answer?.id },
                ]);
              }
            }}
            sx={getAnswerStyle(answer.id)}
          />
        ))}
      </RadioGroup>

      {showResult && (
        <Feedback
          answeredCorrectly={answeredCorrectly}
          explanation={question.answer_explanation}
        />
      )}
    </Container>
  );
};

export default MultipleChoiceQuestion;
