import styled, { css } from 'styled-components';
import Select from '../Select';

export const HeaderContainer = styled.div`
  ${props => {
    const { theme } = props;
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      padding: 0px 30px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${theme.zIndex.levelThree};
      box-shadow: ${theme.shadow};
      background-color: ${theme.colors.black_shade_0};
    `;
  }}
`;

export const HeaderSelect = styled(Select)`
  ${props => {
    const { theme } = props;
    return css`
      max-width: 250px;
      border: none;
      .select-input {
        background-color: transparent;
        font-size: 20px;
        font-weight: 600;
        color: ${theme.colors.black_shade_3};
      }
      .select-arrow {
        border-color: ${theme.colors.black_shade_3};
      }
      .select-list-item {
        color: ${theme.colors.black_shade_3};
        border-top: 1px solid ${theme.colors.black_shade_2};
        &:first-child {
          border-top: none;
        }
        &:hover {
          background-color: ${theme.colors.black_shade_0};
        }
      }
      .select-list-item.is-active {
        background-color: ${theme.colors.black_shade_default};
        color: ${theme.colors.white};
      }
    `;
  }}
`;
