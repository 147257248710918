import axios from 'axios'
export const makeFileUploadRequest = async (file, questionSetName) => {
  const token = JSON.parse(localStorage.getItem('user'));

  const formData = new FormData()
  formData.append('file', file)
  formData.append('questionSetName', questionSetName)
  try {
    return axios.post(`${process.env.REACT_APP_API_URL}/import/questions`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: token?.accessToken ? `Bearer ${token.accessToken}` : "",
      }
    })
  } catch (e) {
    console.log(e)
  }
 
}