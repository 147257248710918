import * as React from "react";

import {
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  IconButton,
  Typography,
  Button
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddUsers from "./AddUser";
import { useEffect, useState } from "react";

import { ColumnContainer } from "../../components/ColumnContainer";

const ProfileUser = ({ users, addUsersToProfile, removeUserFromProfile }) => {

  const [showAddSection, setShowAddSection] = useState(false)

  const [userIds, setUserIds] = React.useState(
    users?.map((u) => Number(u.id))
  );

  useEffect(() => {
    setUserIds(users?.map((u) => Number(u.id)));
  }, [users]);


  const userHeader = <Typography variant='h5'>Profile Users</Typography>
      
  let profileUserButton
  if (!showAddSection) {
    profileUserButton = (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setShowAddSection(true);
        }}
        startIcon={<AddIcon color='primary'/>}
        size="small"
      >
        Add
      </Button>
    )
  }   

  const addUserHeader = <Typography variant='h5'>Add Users</Typography>
  let usersDisplay
  if(users && users.length > 0) {
    const usersList = users.map((u) => (
      <ListItem
        key={u.id}
        disablePadding
        secondaryAction={
          <IconButton aria-label="remove" onClick={() => removeUserFromProfile(u.id)}>
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => console.log(u.id)}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={`${u.firstName} ${u.lastName}`} />
        </ListItemButton>
      </ListItem>
    ));
    usersDisplay = <List>{usersList}</List>
  } else {
    usersDisplay = <div>No users have been assigned to this group</div>
  }
  

  const usersColumn = {
    header: userHeader,
    headerAction: profileUserButton,
    content: usersDisplay,
  };

  let addUsersColumn
  if(showAddSection) {
    addUsersColumn = [{
      header: addUserHeader,
      content: <AddUsers profileUserIds={userIds} addUsers={addUsersToProfile} />,
    }];
  } else {
    addUsersColumn = [];
  }
  return <ColumnContainer columns={[usersColumn, ...addUsersColumn]} />;
};

export default ProfileUser;
