import React from "react";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Chip,
} from "@mui/material";

const TrueFalseQuestionCreator = ({
  setShowTypeQuestion,
  setQuestionType,
  setDifficulty,
  existingQuestion,
}) => {
  // Find the correct answer
  let existingAnswer = "";
  if (existingQuestion) {
    console.log("🚀 ~ existingQuestion:", existingQuestion);
    existingQuestion.answers.forEach((answer) => {
      if (answer.correct) {
        existingAnswer = answer.text;
      }
    });
  }

  return (
    <>
      <div>
        <TextField
          autoFocus
          required
          id="questionText"
          name="questionText"
          label="Question"
          multiline
          rows={4}
          sx={{ marginTop: 2, width: "400px" }}
          autoComplete="off"
          defaultValue={existingQuestion ? existingQuestion.text : ""}
        />
      </div>

      <div>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="trueFalseAnswer"
          sx={{ marginTop: 2, paddingLeft: 1 }}
          defaultValue={
            existingAnswer !== ""
              ? existingAnswer.toLowerCase() === "true"
                ? "true"
                : "false"
              : ""
          }
        >
          <FormControlLabel value="true" control={<Radio />} label="True" />
          <FormControlLabel value="false" control={<Radio />} label="False" />
        </RadioGroup>

        <Divider sx={{ marginTop: 2 }}>
          <Chip label="Answer Details" size="small" />
        </Divider>

        <TextField
          id="answerExplanation"
          name="answerExplanation"
          label="Answer Explanation"
          helperText="Provide some details about why this answer is correct."
          multiline
          rows={2}
          sx={{ marginTop: 2, width: "400px" }}
          autoComplete="off"
          defaultValue={
            existingQuestion ? existingQuestion.answer_explanation : ""
          }
        />
      </div>

      <div>
        <FormControl sx={{ marginTop: 2, width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Difficulty *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Difficulty"
            required
            onChange={(e) => setDifficulty(e.target.value)}
            defaultValue={
              existingQuestion ? existingQuestion.difficulty?.toLowerCase() : ""
            }
          >
            <MenuItem value={"easy"}>Easy</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"hard"}>Hard</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "12px",
        }}
      >
        {!existingQuestion ? (
          <Button
            variant="outlined"
            onClick={() => {
              setQuestionType(null);
              setShowTypeQuestion(true);
            }}
            sx={{ marginRight: "10px" }}
          >
            Back
          </Button>
        ) : null}

        <Button variant="contained" type="submit">
          Save
        </Button>
      </div>
    </>
  );
};

export default TrueFalseQuestionCreator;
