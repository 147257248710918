import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Button
  } from "@mui/material";

const NewProfileDialog = ({ openDialog, setOpenDialog, createNewProfile }) => {
  // const [name, setName] = useState('');

  return (
    <Dialog 
      open={openDialog} 
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData).entries());
          await createNewProfile({ name: formJson.profileName })
          setOpenDialog(false)
        },

      }}
      disableRestoreFocus
    >
      <DialogTitle>Create New Knowledge Group</DialogTitle>
      <DialogContent>
        <TextField 
          autoFocus
          required
          margin="dense"
          id="profileName" 
          name="profileName"
          label="Knowledge Group Name" 
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewProfileDialog;