const convertToObject = (text, ...vars) => {
  let mapList = text.join('').split('\n');
  const valueRegex = /(^[a-zA-Z_]+:)(.+;)/;
  let mapListValueAssignment = [];
  const splitText = val => val.split(':').map(txt => txt.trim());
  mapList = mapList
    .filter(val => {
      const trimmedValue = val.trim();
      const valuePasses = valueRegex.test(trimmedValue);
      if (valuePasses) return trimmedValue;
      return null;
    })
    .map(val => {
      const [key, value] = splitText(val);
      if (value.length <= 1) {
        mapListValueAssignment = [...mapListValueAssignment, value];
        const index = mapListValueAssignment.length - 1;
        return `${key}: ${vars[index]};`;
      }
      return val.trim();
    })
    .reduce((acc, val) => {
      const [key, value] = splitText(val);
      return Object.assign(acc, { [key]: value });
    }, {});

  return mapList;
};

const styleMap = (...args) => props => {
  let map;

  if (Array.isArray(args[0])) {
    map = convertToObject(...args);
  } else {
    console.error('Looks like your styleMap is not formatted properly.');
    return null;
  }

  const propKey = Object.keys(map).find(key => Object.prototype.hasOwnProperty.call(props, key));
  if (propKey) {
    return map[propKey];
  }

  if (Object.prototype.hasOwnProperty.call(map,'default')) {
    return map.default;
  }

  console.warn('Add a default property to your styled map to render out a style.');
  return null;
};

export default styleMap;
