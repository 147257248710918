import React from "react";

import {
  FormControlLabel,
  Button,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Chip,
} from "@mui/material";

const SelectAllThatApplyQuestionCreator = ({
  checkbox1,
  checkbox2,
  checkbox3,
  checkbox4,
  setCheckbox1,
  setCheckbox2,
  setCheckbox3,
  setCheckbox4,
  setShowTypeQuestion,
  setQuestionType,
  setDifficulty,
  existingQuestion,
}) => {
  return (
    <>
      <div>
        <TextField
          autoFocus
          required
          id="questionText"
          name="questionText"
          label="Question"
          multiline
          rows={4}
          sx={{ marginTop: 2, marginLeft: "30px", width: "370px" }}
          autoComplete="off"
          defaultValue={existingQuestion ? existingQuestion.text : ""}
        />
      </div>

      {/* PHIL-TODO: Add dynamic way to add more answers past 2 */}
      <div style={{ width: "400px" }}>
        <FormControlLabel
          value="answer1"
          control={
            <Checkbox
              defaultChecked={
                existingQuestion
                  ? existingQuestion.answers?.[0]?.correct
                  : false
              }
              onClick={() => setCheckbox1(!checkbox1)}
            />
          }
          label={
            <TextField
              required
              id="answer1"
              name="answer1"
              label="Answer 1"
              sx={{ marginTop: 2, width: "370px" }}
              autoComplete="off"
              defaultValue={
                existingQuestion ? existingQuestion.answers?.[0]?.text : ""
              }
            />
          }
        />
        <FormControlLabel
          value="answer2"
          control={
            <Checkbox
              defaultChecked={
                existingQuestion
                  ? existingQuestion.answers?.[1]?.correct
                  : false
              }
              onClick={() => setCheckbox2(!checkbox2)}
            />
          }
          label={
            <TextField
              required
              id="answer2"
              name="answer2"
              label="Answer 2"
              sx={{ marginTop: 2, width: "370px" }}
              autoComplete="off"
              defaultValue={
                existingQuestion ? existingQuestion.answers?.[1]?.text : ""
              }
            />
          }
        />
        <FormControlLabel
          value="answer3"
          control={
            <Checkbox
              defaultChecked={
                existingQuestion
                  ? existingQuestion.answers?.[2]?.correct
                  : false
              }
              onClick={() => setCheckbox3(!checkbox3)}
            />
          }
          label={
            <TextField
              required
              id="answer3"
              name="answer3"
              label="Answer 3"
              sx={{ marginTop: 2, width: "370px" }}
              autoComplete="off"
              defaultValue={
                existingQuestion ? existingQuestion.answers?.[2]?.text : ""
              }
            />
          }
        />
        <FormControlLabel
          value="answer4"
          control={
            <Checkbox
              defaultChecked={
                existingQuestion
                  ? existingQuestion.answers?.[3]?.correct
                  : false
              }
              onClick={() => setCheckbox4(!checkbox4)}
            />
          }
          label={
            <TextField
              required
              id="answer4"
              name="answer4"
              label="Answer 4"
              sx={{ marginTop: 2, width: "370px" }}
              autoComplete="off"
              defaultValue={
                existingQuestion ? existingQuestion.answers?.[3]?.text : ""
              }
            />
          }
        />

        <Divider sx={{ marginTop: 2 }}>
          <Chip label="Answer Details" size="small" />
        </Divider>

        <TextField
          id="answerExplanation"
          name="answerExplanation"
          label="Answer Explanation"
          helperText="Provide some details about why this answer is correct."
          multiline
          rows={2}
          sx={{ marginTop: 2, width: "400px" }}
          autoComplete="off"
          defaultValue={
            existingQuestion ? existingQuestion?.answer_explanation : ""
          }
        />
      </div>

      <div>
        <FormControl sx={{ marginTop: 2, width: "400px" }}>
          <InputLabel id="demo-simple-select-label">Difficulty *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Difficulty"
            required
            onChange={(e) => setDifficulty(e.target.value)}
            defaultValue={
              existingQuestion
                ? existingQuestion?.difficulty?.toLowerCase()
                : ""
            }
          >
            <MenuItem value={"easy"}>Easy</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"hard"}>Hard</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "30px",
        }}
      >
        {!existingQuestion ? (
          <Button
            variant="outlined"
            onClick={() => {
              setQuestionType(null);
              setShowTypeQuestion(true);
            }}
            sx={{ marginRight: "10px" }}
          >
            Back
          </Button>
        ) : null}

        <Button variant="contained" type="submit">
          Save
        </Button>
      </div>
    </>
  );
};

export default SelectAllThatApplyQuestionCreator;
