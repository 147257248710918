import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem('user'));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token?.accessToken ? `Bearer ${token.accessToken}` : "",
    }
  }
});

const resetToken = onError(({ response, networkError }) => {
  if (networkError?.statusCode === 401 || networkError?.statusCode === 403) {
    localStorage.removeItem('user')
    window.location.assign('/login')
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([ resetToken, authLink, httpLink, ]),
  cache: new InMemoryCache(),
});