import React from "react";

import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";

// Create array of positive feedback messages to display when the user answers correctly
const positiveFeedback = [
  "Great job!",
  "You're a genius!",
  "You're on fire!",
  "You're a rockstar!",
  "You're a superstar!",
  "You're a legend!",
  "You're a master!",
  "You're a wizard!",
  "You're a champion!",
  "You're a hero!",
];

// Create array of negative feedback messages to display when the user answers incorrectly
const negativeFeedback = [
  "but better luck next time!",
  "but you're learning!",
  "but you're growing!",
  "but you're getting better!",
  "but you're getting smarter!",
];

const Feedback = ({ answeredCorrectly, explanation }) => {
  if (answeredCorrectly === undefined || answeredCorrectly === null) {
    return null;
  }

  const randomPositiveMsg =
    positiveFeedback[Math.floor(Math.random() * positiveFeedback.length)];
  const randomNegativeMsg =
    negativeFeedback[Math.floor(Math.random() * negativeFeedback.length)];

  return (
    <Box
      component="section"
      sx={
        isMobile
          ? {
              p: 2,
              border: `1px dashed ${answeredCorrectly ? "green" : "red"}`,
              mt: 4,
              width: "80%",
              textAlign: "center",
            }
          : {
              p: 2,
              border: `1px dashed ${answeredCorrectly ? "green" : "red"}`,
              backgroundColor: answeredCorrectly ? "#e6f4ea" : "#f4e6e6",
              mt: 4,
              width: "40%",
              textAlign: "center",
            }
      }
    >
      <h2>
        {answeredCorrectly
          ? `Correct - ${randomPositiveMsg}`
          : `Incorrect, ${randomNegativeMsg}`}
      </h2>
      <Box sx={{ marginTop: "12px" }}>{explanation}</Box>
    </Box>
  );
};

export default Feedback;
