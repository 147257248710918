import React, { useEffect, useState } from "react";

import {
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  IconButton,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_QUESTION_SETS,
  CREATE_QUESTION_SET,
  DELETE_QUESTION_SET,
  CREATE_QUESTION,
  EDIT_QUESTION,
  DELETE_QUESTION,
} from "./questionQueries";
import { ColumnContainer } from "../../components/ColumnContainer";
import NewQuestionSetDialog from "../../components/Dialogs/NewQuestionSetDialog";
import NewQuestionDialog from "../../components/Dialogs/NewQuestion/NewQuestionDialog";
import ConfirmDeleteDialog from "../../components/Dialogs/ConfirmDeleteDialog";
import DropZoneDialog from "../../components/Dialogs/DropZoneDialog";
import { makeFileUploadRequest } from "../../api/questionRequests";

const QuestionsPage = () => {
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);

  // Get Question Sets
  const { loading, data, error, refetch } = useQuery(GET_QUESTION_SETS) || {};
  const { questionSetsWithQuestions } = data || {};

  // After adding questions to a question set, update the state var question set with the new questions
  useEffect(() => {
    if (!selectedQuestionSet) return;

    const updatedQuestionSet = questionSetsWithQuestions.find(
      (questionSet) => questionSet?.id === selectedQuestionSet?.id
    );
    setSelectedQuestionSet(updatedQuestionSet);
  }, [questionSetsWithQuestions]);

  const column1 = {
    header: <Typography variant="h5">Question Sets</Typography>,
    content: (
      <QuestionSets
        questionSetsWithQuestions={questionSetsWithQuestions}
        refetch={refetch}
        selectedQuestionSet={selectedQuestionSet}
        setSelectedQuestionSet={setSelectedQuestionSet}
      />
    ),
  };

  const column2 = {
    header: <Typography variant="h5">Questions</Typography>,
    content: (
      <Questions
        questions={selectedQuestionSet?.questions}
        selectedQuestionSetId={selectedQuestionSet?.id}
        refetch={refetch}
        setSelectedQuestionSet={setSelectedQuestionSet}
      />
    ),
  };

  // Early return if Apollo Client is still loading or has an error
  if (loading) return <CircularProgress />;
  if (error) return <p>Error : {error}</p>;

  return <ColumnContainer columns={[column1, column2]} />;
};

const QuestionSets = ({
  questionSetsWithQuestions,
  refetch,
  selectedQuestionSet,
  setSelectedQuestionSet,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [questionSetToDelete, setQuestionSetToDelete] = useState("");
  const [openDropZoneDialog, setOpenDropZoneDialog] = useState(false);
  const [createQuestionSet] = useMutation(CREATE_QUESTION_SET);
  const [deleteQuestionSet] = useMutation(DELETE_QUESTION_SET);

  const handleCreateQuestionSet = async ({ name }) => {
    const response = await createQuestionSet({
      variables: {
        name: name,
      },
    });

    const createdQuestionSet = response?.data?.createQuestionSet;

    if (createdQuestionSet) {
      setSelectedQuestionSet(createdQuestionSet);
      refetch();
    }
  };

  const handleDeleteQuestionSet = async () => {
    const response = await deleteQuestionSet({
      variables: {
        questionSetId: selectedQuestionSet?.id,
      },
    });

    if (response?.data?.deleteQuestionSet) {
      refetch();
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        Create new
      </Button>
      <Button
        role={undefined}
        variant="outlined"
        startIcon={<CloudUploadIcon color="primary" />}
        onClick={() => {
          setOpenDropZoneDialog(true);
        }}
        sx={{ marginLeft: 2 }}
      >
        Import
      </Button>
      {questionSetsWithQuestions.map((questionSet, index) => (
        <List key={index}>
          <ListItem
            disablePadding
            // conditional styling if the question set is selected
            sx={{
              backgroundColor: questionSet === selectedQuestionSet && "#f0f0f0",
            }}
            onClick={() => {
              // setSelectedQuestions(questionSet?.questions);
              setSelectedQuestionSet(questionSet);
            }}
            secondaryAction={
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setQuestionSetToDelete(questionSet?.name);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText primary={questionSet?.name} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      ))}

      <NewQuestionSetDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        createNewQuestionSet={handleCreateQuestionSet}
      />

      <ConfirmDeleteDialog
        title={`Are you sure that you want to delete the "${questionSetToDelete}" question set?`}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        confirmAction={() => {
          console.log("Deleting question set!");
          handleDeleteQuestionSet();
        }}
      />

      <DropZoneDialog
        title={`Import New Question Set`}
        openDialog={openDropZoneDialog}
        setOpenDialog={setOpenDropZoneDialog}
        confirmAction={async (files) => {
          console.log("FILES -", files)
          if (files && files.length > 0) {
            await makeFileUploadRequest(files[0])
          }
          refetch()
          setOpenDropZoneDialog(false)
        }}
      />
    </div>
  );
};

const Questions = ({ questions, refetch, selectedQuestionSetId }) => {
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [existingQuestion, setExistingQuestion] = useState(null);

  const [createQuestion] = useMutation(CREATE_QUESTION);
  const [editQuestion] = useMutation(EDIT_QUESTION);
  const [deleteQuestion] = useMutation(DELETE_QUESTION);

  const handleCreateQuestion = async ({
    questionSetId,
    questionType,
    text,
    difficulty,
    answerExplanation,
    answers,
  }) => {
    const response = await createQuestion({
      variables: {
        questionSetId,
        questionType,
        text,
        difficulty,
        answerExplanation,
        answers,
      },
    });

    if (response?.data?.createQuestion) {
      refetch();
    }
  };

  const handleEditQuestion = async ({
    questionId,
    text,
    difficulty,
    answerExplanation,
    answers,
  }) => {
    const response = await editQuestion({
      variables: {
        questionId,
        text,
        difficulty,
        answerExplanation,
        answers,
      },
    });

    if (response?.data?.editQuestion) {
      refetch();
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    const response = await deleteQuestion({
      variables: {
        questionId,
      },
    });

    if (response?.data?.deleteQuestion) {
      refetch();
    }
  };

  return (
    <div>
      {selectedQuestionSetId ? (
        <div style={{ marginLeft: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setEditMode(false);
              setExistingQuestion(null);
              setOpenNewDialog(true);
            }}
          >
            Create new
          </Button>
        </div>
      ) : null}

      {questions?.length
        ? questions.map((q) => (
            <ListItem
              key={q.id}
              disablePadding
              secondaryAction={
                <IconButton
                  aria-label="remove"
                  onClick={() => {
                    handleDeleteQuestion(q.id);
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() => {
                  setEditMode(true);
                  setExistingQuestion(q);
                  setOpenNewDialog(true);
                }}
              >
                <ListItemText primary={q.text} secondary={q.type} />
              </ListItemButton>
            </ListItem>
          ))
        : null}

      <NewQuestionDialog
        openDialog={openNewDialog}
        setOpenDialog={setOpenNewDialog}
        handleCreateQuestion={handleCreateQuestion}
        handleEditQuestion={handleEditQuestion}
        questionSetId={selectedQuestionSetId}
        editMode={editMode}
        existingQuestion={existingQuestion}
      />
    </div>
  );
};

export default QuestionsPage;
