export const USER_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  department: '',
  startDate: '',
  userIsActive: false,
  tags: [],
};

export const USER_ACTION_TYPES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',
  DEPARTMENT: 'department',
  START_DATE: 'startDate',
  USER_IS_ACTIVE: 'userIsActive',
  TAGS: 'tags',
  ADD_TAG: 'addTag',
  REMOVE_TAG: 'removeTag',
  INITIALIZE_STATE: 'initializeState',
};

export const USER_STATE_REGEX = {};
