import * as React from 'react';

import {
  Typography,
  Divider,
 } from '@mui/material'

import { Grid } from './themedGrid';

/**
 * Takes in an array of columns and returns a grid with the columns
 */
export const ColumnContainer = ({columns}) => {
    return (
      <Grid container spacing={2} height='90vh'>
        {columns.map((column, index) => {
          return (
            <>
              <Grid key={index} item xs>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {column.header}
                  </Grid>
                  {column.headerAction ?? <Grid item xs={6}>
                    {column.headerAction}
                    </Grid>
                  }
                  <Grid item xs={12}>
                    {column.content}
                  </Grid>
                </Grid>
              </Grid>
              {columns.length - 1 !== index && <Divider orientation="vertical" flexItem /> }
            </>
          )
        })}
      </Grid>
    )
}