import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material'
const AddProfileQuestionPage = (props) => {
  // const [state, dispatch] = useImmerReducer(profileReducer, PROFILE_STATE);

  // const { name, numQuestions, active, tempo } = state;
  const navigate = useNavigate();

  const handleProfileSelect = (profileId) => {
    return props.history.push({
      pathname: '/profile',
      state: {
        selectedProfileId: profileId
      }
    })
  }
  return (
    <>
      <Button variant="text" onClick={() => navigate.goBack()}>Back</Button>
    </>
  );
}

export default AddProfileQuestionPage