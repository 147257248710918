import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
const AuthenticatedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();
  return user ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default AuthenticatedRoute;
