import React, { useState } from "react";
import Dropzone from 'react-dropzone'
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material"

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;


const DropZoneDialog = ({ title, openDialog, setOpenDialog, confirmAction }) => {
  const [files, setFiles] = useState([])

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}  fullWidth={true}
    maxWidth='sm'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Dropzone onDrop={acceptedFiles => setFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles }) => {
            const files = acceptedFiles.map(file => (
              <li key={file.path}>
                {file.path} - {file.size} bytes
              </li>
            ))
            return (
              <section>
                <Container  {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                  <input {...getInputProps()} />
                  <p>Drag  drop a file here, or click to select files</p>
                </Container>
                <aside>
                  <h4>Files:</h4>
                  <ul>{files}</ul>
                </aside>
                
              </section>
            )}
          }
        </Dropzone>
        <Button onClick={() => confirmAction(files)}>Save</Button>
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
    
      </DialogContent>
    </Dialog>
  );
};

export default DropZoneDialog