import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SelectContainer,
  SelectLabel,
  SelectInput,
  SelectArrowIcon,
  SelectList,
  SelectItem,
} from './Select.styles';
import { nodeMatch } from '../../helpers';

const Select = ({ value, onChange, list, ...props }) => {
  const { placeholder, name, type, autoComplete } = props;
  // Create element refs
  const ContainerRef = useRef(null);
  const ListRef = useRef(null);
  const InputRef = useRef(null);

  const [listRefCollection, setListRefCollection] = useState([]);
  const addToListRefCollection = itemRef => {
    if (itemRef && !listRefCollection.includes(itemRef)) {
      listRefCollection.push(itemRef);
      setListRefCollection(listRefCollection);
    }
  };

  const [isFocused, toggleIsFocused] = useState(false);

  const handleOnClick = val => {
    onChange(val);
    toggleIsFocused(false);
    InputRef.current.blur();
  };

  const handleOnChange = ({ target }) => {
    const filteredItem = listRefCollection.find(item => {
      return item.innerText.includes(target.value);
    });

    if (filteredItem) {
      const itemOffsetTop = filteredItem.offsetTop;
      ListRef.current.scrollTop = itemOffsetTop;
    }
    onChange(target.value);
  };

  const handleOnFocus = () => {
    toggleIsFocused(true);
  };

  const handleScrollDown = item => {
    if (item) {
      const listItemElement = listRefCollection.find(listItem => listItem.innerText === item);
      const itemOffsetTop = listItemElement.offsetTop;
      const { current } = ListRef;
      const scrollBottom = current.scrollTop + 250;
      if (itemOffsetTop > scrollBottom) {
        // 299 to keep border in mind
        current.scrollTop = itemOffsetTop - (299 - listItemElement.clientHeight);
      }
      if (itemOffsetTop - current.scrollTop < 0) {
        current.scrollTop = itemOffsetTop;
      }
    }
  };

  const handleScrollUp = item => {
    if (item) {
      const listItemElement = listRefCollection.find(listItem => listItem.innerText === item);
      const itemOffsetTop = listItemElement.offsetTop;
      const { current } = ListRef;
      if (itemOffsetTop < current.scrollTop) {
        current.scrollTop = itemOffsetTop;
      }
      if (itemOffsetTop - current.scrollTop > 255) {
        current.scrollTop = itemOffsetTop;
      }
    }
  };

  // mock the key events for focusing inputs
  const onKeyUpEvent = event => {
    if (event.code === 'Tab') {
      const activeElement = document.activeElement;
      const { current } = InputRef;
      const selectIsActive = activeElement === current;
      if (selectIsActive) {
        toggleIsFocused(true);
      } else {
        toggleIsFocused(false);
      }
    }
  };

  const onKeyDownEvent = event => {
    const { code } = event;
    const activeElement = document.activeElement;
    const { current } = InputRef;
    const selectIsActive = activeElement === current;
    if (selectIsActive) {
      const inputValue = current.value;
      const listHasCurrentValue = list.some(item => item.value === inputValue);
      switch (code) {
        case 'ArrowDown': {
          event.preventDefault();
          if (listHasCurrentValue) {
            const listIndex = list.findIndex(item => item.value === inputValue);
            const isNotLastIndex = listIndex !== list.length - 1;
            if (isNotLastIndex) {
              const nextItem = list[listIndex + 1].value;
              onChange(nextItem);
              handleScrollDown(nextItem);
            }
          } else {
            const nextItem = list[0].value;
            onChange(nextItem);
            handleScrollDown(nextItem);
          }
          break;
        }
        case 'ArrowUp': {
          event.preventDefault();
          if (listHasCurrentValue) {
            const listIndex = list.findIndex(item => item.value === inputValue);
            const isNotFirstIndex = listIndex !== 0;
            if (isNotFirstIndex) {
              const nextItem = list[listIndex - 1].value;
              onChange(nextItem);
              handleScrollUp(nextItem);
            }
          }
          break;
        }
        case 'Enter': {
          if (listHasCurrentValue) {
            onChange(inputValue);
            toggleIsFocused(false);
            InputRef.current.blur();
          }
          break;
        }
        default:
          break;
      }
    }
  };

  // Close the dropdown if you click outside of the select container
  const selectClickEvent = event => {
    const { target } = event;
    const { current } = ContainerRef;
    const isWithinContainer = nodeMatch(current, target);
    if (!isWithinContainer) {
      toggleIsFocused(false);
    }
  };

  // On mount and unmount, add and remove event listeners
  useEffect(() => {
    document.addEventListener('keydown', onKeyDownEvent);
    document.addEventListener('keyup', onKeyUpEvent);
    document.addEventListener('click', selectClickEvent);
    return () => {
      document.removeEventListener('keydown', onKeyDownEvent);
      document.removeEventListener('keyup', onKeyUpEvent);
      document.removeEventListener('click', selectClickEvent);
    };
  }, []);

  return (
    <SelectContainer ref={ContainerRef} className={props.className}>
      <SelectLabel className="select-label" {...props}>
        <SelectInput
          ref={InputRef}
          value={value}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          placeholder={placeholder}
          type={type}
          name={name}
          autoComplete={autoComplete}
          {...props}
          className="select-input"
        />
        <SelectArrowIcon {...props} className="select-arrow" />
      </SelectLabel>
      <SelectList isFocused={isFocused} ref={ListRef} {...props} className="select-list">
        {list.map(item => (
          <SelectItem
            key={item.value}
            onClick={() => handleOnClick(item.value)}
            isActive={value === item.value}
            ref={itemRef => addToListRefCollection(itemRef)}
            {...props}
            className={`select-list-item ${value === item.value && 'is-active'}`}
          >
            {item.name}
          </SelectItem>
        ))}
      </SelectList>
    </SelectContainer>
  );
};

Select.defaultProps = {
  value: '',
  onChange: () => null,
  list: [{ value: '', name: '' }],
  placeholder: '',
  type: 'text',
  name: '',
  autoComplete: 'off',
};

Select.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default Select;
