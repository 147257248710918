import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./styles";
import AppRoutes from "./routes/Routes";
import { ApolloProvider } from "@apollo/client";
import { client } from "./helpers/apolloClient";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";


loadDevMessages();
loadErrorMessages();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Fragment>
          <AppRoutes />
          <GlobalStyle />
        </Fragment>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
