import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth'
import { 
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  Grid,
  Box,
  Container,
 } from '@mui/material'


const LoginPage = (props) => {
  const { login } = useAuth()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (payload) => {
    console.log('PAYLOAD', payload)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      }
    );
    const data = await response.json();           
    login(data)
  }
  return (
    <>
      <Grid
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        flexDirection={"column"}
      >
        
        <Container
          maxWidth={false}
          sx={{
            width: "40ch"
          }}
        >
          <Box
            display={"flex"}
            autoComplete="on"
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '30ch' },
              border: '1px solid grey',
              borderRadius: '5px',
              padding: '5px',
              marginBottom: '10px',
              boxShadow: 7,
              height: "20em"
            }}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Typography variant='h6'>Login Form</Typography>
            <TextField 
              fullWidth
              id="email" 
              label="Email" 
              variant="outlined" 
              onChange={({ target }) => setEmail(target.value)}
            />
            <TextField 
              fullWidth
              id="password" 
              label="Password" 
              variant="outlined" 
              type="password"
              onChange={({ target }) => setPassword(target.value)}
            />
            <Button fullWidth sx={{marginTop: '15px'}} onClick={() => handleLogin({email, password})} variant="contained">Login</Button>
          </Box>
          
        </Container>
        
      </Grid>
    </>
  );
};

export default LoginPage;
