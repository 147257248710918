import * as React from 'react';

import { 
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Button
 } from '@mui/material'

 import Checkbox from '@mui/material/Checkbox';
 import SaveIcon from "@mui/icons-material/Save";
import { useQuery } from '@apollo/client';
import { GET_USERS } from './profileQueries';
import { useEffect } from 'react';

const AddUsers = (props) => {
  const [checked, setChecked] = React.useState({});
  const { addUsers } = props
  
  const handleSave = async () => {
    const userIdArray = Object.keys(checked).filter(id => checked[id]).map(id => Number(id))
    await addUsers(userIdArray)
    setChecked({})
  }

  const toggleCheckbox = (userId) => {
    const value = !checked[userId]
    setChecked(checked => ({ ...checked, [userId]: value }))
  }
  
  // useEffect(() => {
  //   const ids = props.profileUserIds?.map(u => 
  //      parseInt(u)
  //    );

  //    setChecked(ids)
  //  }, [props.profileUserIds])

  const { loading, data, error } = useQuery(GET_USERS)
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const filteredUsers = data.users?.filter(u => !props.profileUserIds?.includes(u.id))

  let users = <div>No available users to assign</div>
  if(filteredUsers.length > 0) {
    const userList = filteredUsers.map(u => {
      const labelId = `checkbox-list-label-${u}`;
      return (
        <ListItem
          key={u.id}
          disablePadding
        >
          <ListItemButton role={undefined} onClick={() => toggleCheckbox(u.id)} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked[u.id] ?? false}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${u.firstName} ${u.lastName}`}/>
          </ListItemButton>
        </ListItem>
      );
    })
    users = (
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {userList}
      </List>
    )
  }
  return (
    <div>
      {Object.values(checked).some(c => c) && <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          handleSave();
        }}
        startIcon={<SaveIcon color='primary'/>}
        size="small"
      >
        Save
      </Button>}
      {users}
    </div>
  )
}
export default AddUsers