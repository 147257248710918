import { gql } from "@apollo/client";

export const GET_QUESTION_SETS = gql`
  {
    questionSetsWithQuestions {
      id
      name
      questions {
        id
        text
        type
        answer_explanation
        difficulty
        answers {
          id
          text
          correct
        }
      }
    }
  }
`;

export const CREATE_QUESTION_SET = gql`
  mutation CreateQuestionSet($name: String!) {
    createQuestionSet(name: $name) {
      id
      name
    }
  }
`;

export const DELETE_QUESTION_SET = gql`
  mutation DeleteQuestionSet($questionSetId: Int!) {
    deleteQuestionSet(questionSetId: $questionSetId) {
      success
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion(
    $questionSetId: Int!
    $questionType: String!
    $text: String!
    $difficulty: String!
    $answerExplanation: String!
    $answers: [AnswerInput]
  ) {
    createQuestion(
      questionSetId: $questionSetId
      questionType: $questionType
      text: $text
      difficulty: $difficulty
      answerExplanation: $answerExplanation
      answers: $answers
    ) {
      id
      text
      difficulty
      answer_explanation
    }
  }
`;

export const EDIT_QUESTION = gql`
  mutation EditQuestion(
    $questionId: Int!
    $text: String!
    $difficulty: String!
    $answerExplanation: String!
    $answers: [AnswerInput]
  ) {
    editQuestion(
      questionId: $questionId
      text: $text
      difficulty: $difficulty
      answerExplanation: $answerExplanation
      answers: $answers
    ) {
      id
      text
      difficulty
      answer_explanation
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($questionId: Int!) {
    deleteQuestion(questionId: $questionId) {
      success
    }
  }
`;
