import * as React from 'react';

import {
  Button,
 } from '@mui/material'
 
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from "react-router-dom";
/**
 * Takes in an array of columns and returns a grid with the columns
 */
export const AuthButton = () => {
    const { user, logout } = useAuth()
    const navigate = useNavigate();
  
    const goToSignInPage = () => {
      navigate("/login", { replace: true });
    }
    return user ? (
      <Button onClick={logout}>Log Out</Button>
    ) : null
}