import styled, { css } from 'styled-components';
import styleMap from '../../styles/styleMap';

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectLabel = styled.label`
  ${props => {
    const { theme } = props;
    return css`
      border-radius: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      padding: 1em;
      max-height: 60px;
      border: 2px solid ${styleMap`
        primary: ${theme.colors.blue_shade_2};
        secondary: ${theme.colors.orange_shade_2};
        success: ${theme.colors.green_shade_2};
        warning: ${theme.colors.red_shade_2};
        default: ${theme.colors.black_shade_3};
        disabled: ${theme.colors.black_shade_1};
      `};
    `;
  }}
`;

export const SelectInput = styled.input`
  ${props => {
    const { theme } = props;
    return css`
      padding: 0;
      margin: 0;
      width: 100%;
      border: none;
      font-size: 16px;
      color: ${styleMap`
        primary: ${theme.colors.blue_shade_default};
        secondary: ${theme.colors.orange_shade_default};
        success: ${theme.colors.green_shade_default};
        warning: ${theme.colors.red_shade_default};
        default: ${theme.colors.black_shade_default};
        disabled: ${theme.colors.black_shade_default};
      `};
      &::placeholder {
        color: ${styleMap`
          primary: ${theme.colors.blue_shade_2};
          secondary: ${theme.colors.orange_shade_2};
          success: ${theme.colors.green_shade_2};
          warning: ${theme.colors.red_shade_2};
          default: ${theme.colors.black_shade_3};
          disabled: ${theme.colors.black_shade_1};
        `};
      }
      &:focus {
        outline: none;
      }
    `;
  }}
`;

export const SelectArrowIcon = styled.span`
  ${props => {
    const { theme } = props;
    return css`
      height: 12px;
      width: 12px;
      border-bottom: 4px solid ${styleMap`
        primary: ${theme.colors.blue_shade_2};
        secondary: ${theme.colors.orange_shade_2};
        success: ${theme.colors.green_shade_2};
        warning: ${theme.colors.red_shade_2};
        default: ${theme.colors.black_shade_2};
        disabled: ${theme.colors.black_shade_1};
      `};
      border-left: 4px solid ${styleMap`
        primary: ${theme.colors.blue_shade_2};
        secondary: ${theme.colors.orange_shade_2};
        success: ${theme.colors.green_shade_2};
        warning: ${theme.colors.red_shade_2};
        default: ${theme.colors.black_shade_2};
        disabled: ${theme.colors.black_shade_1};
      `};
      transform: rotate(-45deg);
      margin-bottom: 6px;
      border-radius: 2px;
    `;
  }}
`;

export const SelectList = styled.ul`
  ${props => {
    const { theme, isFocused } = props;
    return css`
      z-index: ${theme.zIndex.levelEight}
      position: absolute;
      left: 0;
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: none;
      opacity: 0;
      transform: translateY(10px);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background-color: ${theme.colors.white};
      max-height: 300px;
      overflow-y: scroll;
      ${isFocused &&
        css`
          display: block;
          opacity: 1;
          transform: translateY(0px);
        `}
    `;
  }}
`;

export const SelectItem = styled.li`
  ${props => {
    const { theme, isActive } = props;
    return css`
      padding: 15px;
      font-size: 16px;
      font-weight: 400;
      color: ${styleMap`
        primary: ${theme.colors.blue_shade_default};
        secondary: ${theme.colors.orange_shade_default};
        success: ${theme.colors.green_shade_default};
        warning: ${theme.colors.red_shade_default};
        default: ${theme.colors.black_shade_default};
        disabled: ${theme.colors.black_shade_default};
      `};
      border-top: 1px solid ${styleMap`
        primary: ${theme.colors.blue_shade_2};
        secondary: ${theme.colors.orange_shade_2};
        success: ${theme.colors.green_shade_2};
        warning: ${theme.colors.red_shade_2};
        default: ${theme.colors.black_shade_3};
        disabled: ${theme.colors.black_shade_1};
      `};
      &:first-child {
        border-top: none;
      }
      &:hover {
        cursor: pointer;
        background-color: ${styleMap`
          primary: ${theme.colors.blue_shade_0};
          secondary: ${theme.colors.orange_shade_0};
          success: ${theme.colors.green_shade_0};
          warning: ${theme.colors.red_shade_0};
          default: ${theme.colors.black_shade_0};
          disabled: ${theme.colors.black_shade_0};
        `};
      }
      ${isActive &&
        css`
          background-color: ${styleMap`
            primary: ${theme.colors.blue_shade_default};
            secondary: ${theme.colors.orange_shade_default};
            success: ${theme.colors.green_shade_default};
            warning: ${theme.colors.red_shade_default};
            default: ${theme.colors.black_shade_3};
            disabled: ${theme.colors.black_shade_default};
          `};
          color: ${theme.colors.white};
        `}
    `;
  }}
`;
