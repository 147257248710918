import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import NonAuthenticatedRoute from "./NonAuthenticatedRoute";
import { Dashboard } from "../pages/Dashboard";
import { ProfilesPage } from "../pages/ProfilesPage";
import { ProfilePage } from "../pages/ProfilePage";
import { QuestionsPage } from "../pages/QuestionsPage";
import { UsersPage } from "../pages/UsersPage";
import { AddQuestionPage } from "../pages/ProfilePage";
import { QuizPage } from "../pages/QuizPage";
import { LoginPage } from "../pages/LoginPage"
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import PageContainer from "../components/PageContainer/PageContainer";
import { AuthProvider } from "../hooks/useAuth";
const AppRoutes = () => {
  
  return (
    
      <Router>
        <AuthProvider>
          <Header />
          <SideNav />
          <PageContainer>
            <Routes>
              <Route element={<LoginPage />} path="/login" />
              <Route exact path="/" element={<Navigate to="/profiles" />} />
              <Route element={<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>} path="/dashboard" />
              <Route element={<AuthenticatedRoute><ProfilesPage /></AuthenticatedRoute>} path="/profiles" />
              <Route element={<AuthenticatedRoute><ProfilePage /></AuthenticatedRoute>} path="/profile" />
              <Route element={<AuthenticatedRoute><AddQuestionPage /></AuthenticatedRoute>} path="/add-question" />
              <Route element={<AuthenticatedRoute><QuestionsPage /></AuthenticatedRoute>} path="/questions" />
              <Route element={<AuthenticatedRoute><UsersPage /></AuthenticatedRoute>} path="/users" />
              <Route element={<QuizPage />} path="/start" />
            </Routes>
          </PageContainer>
        </AuthProvider>
      </Router>
    
  );
};

export default AppRoutes;
