import { gql } from '@apollo/client';

export const GET_PROFILES = gql`
  {
    profiles {
      id
      name
      numQuestions
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query Profile($profileId: Int!) {
    profile(profileId: $profileId) {
      id
      name
      numQuestions
      users {
        id
        firstName
        lastName
        email
      }
      questions {
        id
        text
        questionType
      }
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation CreateProfile($name: String!, $numQuestions: Int, $tempo: TempoInput) {
    createProfile(name: $name, numQuestions: $numQuestions, tempo: $tempo) {
      id
      name
      numQuestions
      users {
        id
        firstName
        lastName
        email
      }
      questions {
        id
        text
        type
      }
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation DeleteProfile($profileId: Int!) {
    deleteProfile(profileId: $profileId) {
      success
    }
  }
`;
