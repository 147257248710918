import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $startDate: String!) {
    createUser(user: { firstName: $firstName, lastName: $lastName, email: $email, startDate: $startDate }) {
      id
      firstName
      lastName
      email
      startDate
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: Int!, $firstName: String!, $lastName: String!, $email: String!, $startDate: String!) {
    updateUser(userId: $userId, user: { firstName: $firstName, lastName: $lastName, email: $email, startDate: $startDate }) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation DeleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      success
    }
  }
`;


export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      firstName
      lastName
      email
      startDate
    }
  }
`;
