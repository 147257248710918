import React, { useEffect } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";

import TrueFalseQuestionCreator from "./TrueFalseQuestionCreator";
import MultipleChoiceQuestionCreator from "./MultipleChoiceQuestionCreator";
import SelectAllThatApplyQuestionCreator from "./SelectAllThatApplyQuestionCreator";

const NewQuestionDialog = ({
  openDialog,
  setOpenDialog,
  handleCreateQuestion,
  handleEditQuestion,
  questionSetId,
  editMode,
  existingQuestion,
}) => {
  const [questionType, setQuestionType] = React.useState(
    existingQuestion?.type || ""
  );
  const [showTypeQuestion, setShowTypeQuestion] = React.useState(true);
  const [checkbox1, setCheckbox1] = React.useState(false);
  const [checkbox2, setCheckbox2] = React.useState(false);
  const [checkbox3, setCheckbox3] = React.useState(false);
  const [checkbox4, setCheckbox4] = React.useState(false);
  const [difficulty, setDifficulty] = React.useState("");

  // Change questionType if existingQuestion changes
  useEffect(() => {
    if (existingQuestion) {
      setQuestionType(existingQuestion.type?.toLowerCase());
    }
  }, [existingQuestion]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const { questionText: text, answerExplanation } = formJson || {};

    if (editMode) {
      await handleEditQuestion({
        questionId: existingQuestion.id,
        text,
        difficulty: existingQuestion.difficulty?.toLowerCase(),
        answerExplanation,
        answers: parseAnswers(formJson),
      });
      setOpenDialog(false);
      return;
    }

    await handleCreateQuestion({
      questionSetId,
      questionType,
      text,
      difficulty,
      answers: parseAnswers(formJson),
      answerExplanation,
    });
    setOpenDialog(false);
  };

  const parseAnswers = (formJson) => {
    const { trueFalseAnswer, selectedAnswer } = formJson || {};
    const multipleChoiceAnswers = [];
    const selectAllAnswers = [];

    switch (questionType?.toLowerCase()) {
      case "truefalse":
        return [
          { text: "true", correct: trueFalseAnswer === "true" },
          { text: "false", correct: trueFalseAnswer === "false" },
        ];
      case "multiplechoice":
        for (let i = 1; i <= 4; i++) {
          if (formJson[`answer${i}`]) {
            multipleChoiceAnswers.push({
              text: formJson[`answer${i}`],
              correct: selectedAnswer === `answer${i}`,
            });
          }
        }
        return multipleChoiceAnswers;
      case "selectall":
        for (let i = 1; i <= 4; i++) {
          if (formJson[`answer${i}`]) {
            selectAllAnswers.push({
              text: formJson[`answer${i}`],
              correct: formJson[`checkbox${i}`] === "on",
            });
          }
        }
        return selectAllAnswers;
      default:
        return [];
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
        setShowTypeQuestion(true);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{editMode ? "Edit" : "Create New"} Question</DialogTitle>
      <DialogContent>
        {showTypeQuestion && !editMode ? (
          <FormControl>
            <div style={{ width: "400px" }}>
              <FormLabel id="demo-radio-buttons-group-label">
                Question Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => {
                  setQuestionType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="truefalse"
                  control={<Radio />}
                  label="True/False"
                />
                <FormControlLabel
                  value="multiplechoice"
                  control={<Radio />}
                  label="Multiple Choice"
                />
                <FormControlLabel
                  value="selectall"
                  control={<Radio />}
                  label="Select All that Apply"
                />
              </RadioGroup>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "12px",
              }}
            >
              <Button
                onClick={() => {
                  if (!questionType) return;

                  setShowTypeQuestion(false);
                }}
                variant="contained"
              >
                Next
              </Button>
            </div>
          </FormControl>
        ) : questionType?.toLowerCase() === "truefalse" ? (
          <TrueFalseQuestionCreator
            setShowTypeQuestion={setShowTypeQuestion}
            setQuestionType={setQuestionType}
            setDifficulty={setDifficulty}
            existingQuestion={existingQuestion}
          />
        ) : questionType?.toLowerCase() === "multiplechoice" ? (
          <MultipleChoiceQuestionCreator
            setShowTypeQuestion={setShowTypeQuestion}
            setQuestionType={setQuestionType}
            setDifficulty={setDifficulty}
            existingQuestion={existingQuestion}
          />
        ) : questionType?.toLowerCase() === "selectall" ? (
          <SelectAllThatApplyQuestionCreator
            checkbox1={checkbox1}
            checkbox2={checkbox2}
            checkbox3={checkbox3}
            checkbox4={checkbox4}
            setCheckbox1={setCheckbox1}
            setCheckbox2={setCheckbox2}
            setCheckbox3={setCheckbox3}
            setCheckbox4={setCheckbox4}
            setShowTypeQuestion={setShowTypeQuestion}
            setQuestionType={setQuestionType}
            setDifficulty={setDifficulty}
            existingQuestion={existingQuestion}
          />
        ) : (
          <div>Invalid Question Type</div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewQuestionDialog;
