import { gql } from '@apollo/client';

export const GET_QUESTION_SETS = gql`
  query GetQuestionSetsWithQuestions {
    questionSetsWithQuestions {
      id
      name
      questions {
        id
        questionSetId
        text
        type
      }
    }
  }
`

export const GET_PROFILES = gql`
  {
    profiles {
      id
      name
      numQuestions
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($profileId: Int!) {
    profile(profileId: $profileId) {
      id
      name
      numQuestions
      users {
        id
        firstName
        lastName
        email
      }
      questions {
        id
        text
        type
      }
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const GET_PROFILE_QUESTIONS = gql`
  query GetProfileQuestions($profileId: Int!) {
    profile(profileId: $profileId) {
      id
      name
      questions {
        id
        questionSetId
        text
        type
      }
    }
  }
`;

export const GET_USERS = gql`
  {
    users {
      id
      firstName
      lastName
      startDate
      email
    }
  }
`;

export const CREATE_PROFILE = gql`
  query CreateProfile($name: String!, $numQuestions: Int!, $tempo: TempoInput) {
    createProfile(name: $name, numQuestions: $numQuestions, tempo: $tempo) {
      id
      name
      numQuestions
      users {
        id
        firstName
        lastName
        email
      }
      questions {
        id
        text
        type
      }
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profileId: Int!, $name: String, $numQuestions: Int, $tempo: TempoInput) {
    updateProfile(profileId: $profileId, name: $name, numQuestions: $numQuestions, tempo: $tempo) {
      id
      name
      numQuestions
      users {
        id
        firstName
        lastName
        email
      }
      questions {
        id
        text
        type
      }
      tempo {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        time
      }
    }
  }
`;

export const ADD_USERS_TO_PROFILE = gql`
  mutation AddUsersToProfile($profileId: Int!, $userIds: [Int]!) {
    addUsersToProfile(profileId: $profileId, userIds: $userIds) {
      success
    }
  }
`;

export const REMOVE_USER_FROM_PROFILE = gql`
  mutation RemoveUserFromProfile($profileId: Int!, $userId: Int!) {
    removeUserFromProfile(profileId: $profileId, userId: $userId) {
      success
    }
  }
`;

export const ADD_QUESTION_SETS_TO_PROFILE = gql`
  mutation AddQuestionSetsToProfile($profileId: Int!, $questionSetIds: [Int]!) {
    addQuestionSetsToProfile(profileId: $profileId, questionSetIds: $questionSetIds) {
      success
    }
  }
`;

export const REMOVE_QUESTION_SET_FROM_PROFILE = gql`
  mutation RemoveQuestionSetFromProfile($profileId: Int!, $questionSetId: Int!) {
    removeQuestionSetFromProfile(profileId: $profileId, questionSetId: $questionSetId) {
      success
    }
  }
`;

export const ADD_QUESTION_TO_PROFILE = gql`
  mutation AddQuestionToProfile($profileId: Int!, $questionId: Int!) {
    addQuestionToProfile(profileId: $profileId, questionId: $questionId) {
      success
    }
  }
`;

export const REMOVE_QUESTION_FROM_PROFILE = gql`
  mutation RemoveQuestionFromProfile($profileId: Int!, $questionId: Int!) {
    removeQuestionFromProfile(profileId: $profileId, questionId: $questionId) {
      success
    }
  }
`;