import * as React from "react";

import {
  // ListItem,
  // List,
  // ListItemText,
  // ListItemButton,
  // ListItemIcon,
  // Typography,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // Grid,
  Button
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { useQuery } from "@apollo/client";
import { GET_QUESTION_SETS } from "./profileQueries";
import { useEffect, useCallback } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddQuestions = (props) => {
  const { questionSets, addQuestionSets } = props;
  const [checked, setChecked] = React.useState(props.profileQuestionIds || []);
  const [selectedSets, setSelectedSets] = React.useState([]);
  

  const handleBankCheck = (value) => {
    // event.stopPropagation();
    setSelectedSets(value)
  }

  const handleSave = async () => {
    console.log('SAVING -- ', selectedSets);
    await addQuestionSets(selectedSets)
    setSelectedSets([])
  }

  useEffect(() => {
    const ids = props.profileQuestionIds?.map((q) => parseInt(q));

    setChecked(ids);
  }, [props.profileQuestionIds]);


  return (
    <div>
    { selectedSets?.length > 0 && <Button
      color="primary"
      variant="outlined"
      onClick={() => handleSave()}
      startIcon={<SaveIcon color='primary'/>}
      size="small"
    >
      Save
    </Button>
    }
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={questionSets || []}
      disableCloseOnSelect
      value={selectedSets}
      onChange={(_, newValue ) => handleBankCheck(newValue)}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Search" 
          placeholder="Added Question Sets"  
          variant="standard"
        />
      )}
    />
    </div>
  );
  // const banks = data.banksWithQuestions.map((b, index) => (
  //   <Accordion key={index}>
  //     <AccordionSummary
  //       expandIcon={<ExpandMoreIcon />}
  //       aria-controls="panel1-content"
  //       id="panel1-header"
  //     >
  //        <Grid item xs={1}> 
  //         <Checkbox
  //           size="small"
  //           checked={checked[b.id]}
  //           onClick={(event) => handleBankCheck(event)}
  //           disableRipple
  //           inputProps={{ "aria-labelledby": b.id }}
  //           style={{ padding:0 }}
  //         />
  //       </Grid> 
  //       <Grid item xs={10} alignItems="center">
  //         <Typography>{b.name}</Typography>
  //       </Grid>
  //     </AccordionSummary>
  //     <AccordionDetails>
  //       <List
  //         sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
  //       >
  //         {b.questions?.map((q) => {
  //           const labelId = `checkbox-list-label-${q}`;

  //           return (
  //             <ListItem key={q.id} disablePadding>
  //               <ListItemButton
  //                 role={undefined}
  //                 onClick={handleToggle(q)}
  //                 dense
  //               >
  //                 <ListItemIcon>
  //                   <Checkbox
  //                     edge="start"
  //                     checked={checked.indexOf(q.id) !== -1}
  //                     tabIndex={-1}
  //                     disableRipple
  //                     inputProps={{ "aria-labelledby": labelId }}
  //                   />
  //                 </ListItemIcon>
  //                 <ListItemText
  //                   id={labelId}
  //                   primary={q.text}
  //                   secondary={q.type}
  //                 />
  //               </ListItemButton>
  //             </ListItem>
  //           );
  //         })}
  //       </List>
  //     </AccordionDetails>
  //   </Accordion>
  // ));
  // return <div>{banks}</div>;
};
export default AddQuestions;
