import { PROFILE_STATE, PROFILE_ACTION_TYPES } from '../../constants';
import { useImmerReducer } from 'use-immer';
import {
  TextField,
  Stack,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { Fragment, useEffect, useState } from 'react';
const { INITIALIZE, NAME, NUM_QUESTIONS, ACTIVE, TEMPO, TIME } = PROFILE_ACTION_TYPES;
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
const profileReducer = (draft, action) => {
  
  const { type, value } = action;
  switch (type) {
    case INITIALIZE:{
      console.log('INITIALIZING -', value)
      let tempo = value.tempo
      if(!tempo) {
        tempo = {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        }
      }
      let time = 0
      if(tempo?.time) {
        time = dayjs().hour(Number(tempo.time)).utc(true).local()
      } else {
        time = dayjs()
      }
      draft =  { ...draft, ...{ ...value, tempo, time } }
      return draft
    }
    case NAME:
      draft[NAME] = value;
      return draft;
    case NUM_QUESTIONS: {
      const numQuestion = Number(value)
      if(isNaN(numQuestion)) return draft
      draft[NUM_QUESTIONS] = numQuestion;
      return draft;
    }
    case ACTIVE:
      draft[ACTIVE] = value;
      return draft;
    case TEMPO: {
      const currentValue = draft[TEMPO][value] || false
      draft[TEMPO][value] = !currentValue
      return draft;
    }
    case TIME:
      console.log('TIME - ', value);
      draft[TIME] = value;
      return draft;
    default:
      break;
  }
};

const ProfileGeneral = ({ profile, updateProfile, updateSuccess, setUpdateSuccess}) => {

  const [state, dispatch] = useImmerReducer(profileReducer, PROFILE_STATE.profile);
  // const { profile } = props;
  useEffect(() => {
    dispatch({type: 'initialize', value: profile});
  }, [dispatch, profile])

  const handleChange = (value, type) => {
    dispatch({ value, type });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSuccess(false);
  };
  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <div>
      <Stack spacing={3} sx={{ width: '50%' }}>
        <TextField 
          id="profileName" 
          label="Profile Name" 
          variant="outlined" 
          value={state.name}
          onChange={({ target }) => handleChange(target.value, NAME)}
        />

        <TextField 
          id="numQuestions" 
          label="Number of Questions" 
          variant="outlined" 
          value={state.numQuestions}
          onChange={({ target }) => handleChange(target.value, NUM_QUESTIONS)}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Days</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="sunday"
              control={<Checkbox checked={!!state.tempo?.sunday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Sun"
              labelPlacement="top"
            />
            <FormControlLabel
              value="monday"
              control={<Checkbox checked={!!state.tempo?.monday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Mon"
              labelPlacement="top"
            />
            <FormControlLabel
              value="tuesday"
              control={<Checkbox checked={!!state.tempo?.tuesday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Tue"
              labelPlacement="top"
            />
            <FormControlLabel
              value="wednesday"
              control={<Checkbox checked={!!state.tempo?.wednesday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Wed"
              labelPlacement="top"
            />
            <FormControlLabel
              value="thursday"
              control={<Checkbox checked={!!state.tempo?.thursday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Thur"
              labelPlacement="top"
            />
            <FormControlLabel
              value="friday"
              control={<Checkbox checked={!!state.tempo?.friday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Fri"
              labelPlacement="top"
            />
            <FormControlLabel
              value="saturday"
              control={<Checkbox checked={!!state.tempo?.saturday} onChange={({ target }) => handleChange(target.value, TEMPO)}/>}
              label="Sat"
              labelPlacement="top"
            />
          </FormGroup>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeClock
            label="Time of Day"
            views={["hours"]} 
            ampmInClock={true}
            value={state.time}
            onChange={(value) => handleChange(value, TIME)}
          />
        </LocalizationProvider>
        <Button style={{ maxWidth: '100px'}} variant="contained" onClick={() => updateProfile(state)}>Save</Button>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={updateSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Updated successfully!"
        action={action}
      />
    </div>
  )
}

export default ProfileGeneral;