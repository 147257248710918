import React from "react";

import {
  Button,
    Dialog,
    DialogTitle,
  } from "@mui/material";

const ConfirmDeleteDialog = ({ title, openDialog, setOpenDialog, confirmAction }) => {
    return (
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{title}</DialogTitle>

      <div style={{display: 'flex', justifyContent: 'end', paddingBottom: '12px'}}>
        <Button
          variant="contained"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          No
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            confirmAction();
            setOpenDialog(false);
          }}
          sx={{ marginLeft: 2, marginRight: 2}}
        >
          Yes
        </Button>
      </div>
        
      </Dialog>
    );
  };

export default ConfirmDeleteDialog;