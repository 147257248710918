import dayjs from 'dayjs';

export const PROFILE_STATE = {
  profiles: [],
  selectedProfileId: 0,
  profile: {
    id: '',
    name: '',
    numQuestions: '',
    tempo: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      time: '2024-02-07T15:00',
    },
    time: dayjs('2024-02-07T15:00'),
    active: true,
    users: [],
    questions: [],
  },
};

export const PROFILE_ACTION_TYPES = {
  INITIALIZE: 'initialize',
  NAME: 'name',
  NUM_QUESTIONS: 'numQuestions',
  ACTIVE: 'active',
  TEMPO: 'tempo',
  TIME: 'time'
};
