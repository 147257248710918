import React, { useState } from "react";
import { HeaderContainer, HeaderSelect } from "./Header.styles";
import TempLogo from "../../images/Temp_Logo.png";
import { DEPARTMENT_NAMES } from "../../constants";
import AtlasLogo from "../../images/atlas-icon.png";
import { AuthButton } from "../AuthButton";
const Header = () => {
  // const [department, setDepartment] = useState(DEPARTMENT_NAMES[0].name);
  return (
    <HeaderContainer>
      <img src={AtlasLogo} alt="logo" height="70px" width="70px" />
      <AuthButton />
    </HeaderContainer>
  );
};

export default Header;
