import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { isMobile } from "react-device-detect";
import Feedback from "./Feeback";

const SelectAllQuestion = ({
  existingQuestion,
  showResult,
  setAnswers,
  answers,
}) => {
  // Find the correct answer
  let existingAnswerIndex = "";
  if (existingQuestion) {
    existingQuestion.answers.forEach((answer, index) => {
      if (answer.correct) {
        existingAnswerIndex = index;
      }
    });
  }

  // Find the answer for the current question from the answers array
  let selectedAnswer = "";
  answers.forEach((answer) => {
    if (answer.questionId === existingQuestion.id) {
      selectedAnswer = answer.answer;
    }
  });

  const answeredCorrectly =
    existingQuestion.answers[existingAnswerIndex]?.text === selectedAnswer;

  return (
    <Container
      sx={{
        height: "450px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "20px", fontSize: "32px" }}>
        {existingQuestion?.text}
      </div>
      {existingQuestion.answers.map((answer, index) => (
        <FormControlLabel
          key={answer.id}
          control={<Checkbox />}
          label={answer?.text}
          disabled={showResult}
          onClick={() => {
            if (!showResult) {
              setAnswers((prev) => [
                ...prev.filter(
                  (prevAnswer) => prevAnswer.questionId !== existingQuestion.id
                ),
                { questionId: existingQuestion.id, answer: answer.text },
              ]);
            }
          }}
        />
      ))}

      {showResult && (
        <Feedback
          answeredCorrectly={answeredCorrectly}
          explanation={existingQuestion.answer_explanation}
        />
      )}
    </Container>
  );
};

export default SelectAllQuestion;
