import { createGlobalStyle } from 'styled-components';
import Theme from './theme';
const { colors } = Theme;

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		font-family: 'Roboto', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	h1, h2, h3, h4, h5, h6, p, input, span {
		color: ${colors.black_shade_dark};
	}
	p {
		letter-spacing: .5px;
		line-height: 1.2;
		b {
			font-weight: 600;
		}
		i {
			font-style: italic;
		}
	}
	h1 {
		font-weight: 900;
	}
	h2 {
		font-weight: 700;
	}
	h3 {
		font-weight: 500;
	}
	h4 {
		font-weight: 400;
	}
	h5 {
		font-weight: 300;
	}
	h6 {
		font-weight: 100;
	}
`;

export default GlobalStyle;