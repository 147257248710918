import { useState } from "react";

import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Button,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddQuestions from "./AddQuestions";
import { useQuery, useMutation } from '@apollo/client';

import { ColumnContainer } from "../../components/ColumnContainer";
import { 
  ADD_QUESTION_TO_PROFILE, 
  REMOVE_QUESTION_FROM_PROFILE, 
  ADD_QUESTION_SETS_TO_PROFILE,
  REMOVE_QUESTION_SET_FROM_PROFILE,
  GET_PROFILE_QUESTIONS,
  GET_QUESTION_SETS
} from './profileQueries';
const ProfileQuestion = (props) => {
  const {
    profileId
  } = props
  
  const [questionSetIds, setQuestionSetIds] = useState([]);
  const [showAddSection, setShowAddSection] = useState(false)
  const [questionAddedSuccess, setQuestionAddedSuccess] = useState(false);
  const [checked, setChecked] = useState({});

  const [addQuestionSetsToProfile] = useMutation(ADD_QUESTION_SETS_TO_PROFILE)
  const handleAddQuestionSets = async (questionSets) => {
    console.log('ADDING SET --', questionSets)
    if(!profileId || questionSets?.length === 0) return null
    
    await addQuestionSetsToProfile({
      variables: {
        profileId,
        questionSetIds: questionSets.map(qs => qs.id)
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        'GetProfileQuestions' // Query name
      ],
    })

    setQuestionAddedSuccess(true)
  }

  const [removeQuestionSetFromProfile] = useMutation(REMOVE_QUESTION_SET_FROM_PROFILE)
  const handleRemoveQuestionSet = async (questionSetId) => {
    console.log('Removing SET --', questionSetId)
    if(!profileId || questionSetId) return null
    
    await removeQuestionSetFromProfile({
      variables: {
        profileId,
        questionSetId: questionSetId
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        'GetProfileQuestions' // Query name
      ],
    })

    setQuestionAddedSuccess(true)
  }

  const [addQuestionToProfile] = useMutation(ADD_QUESTION_TO_PROFILE)
  const handleAddQuestion = async (questionId) => {
    console.log('ADDING QUESTION --', questionId)
    if(!profileId || !questionId) return null
    
    await addQuestionToProfile({
      variables: {
        profileId,
        questionId,      
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        'GetProfileQuestions' // Query name
      ],
    })

    setQuestionAddedSuccess(true)
  }

  const [removeQuestionFromProfile] = useMutation(REMOVE_QUESTION_FROM_PROFILE)
  const handleRemoveQuestion = async (questionId) => {
    console.log('REMOVING QUESTION --', questionId)
    if(!profileId || !questionId) return null
    
    await removeQuestionFromProfile({
      variables: {
        profileId,
        questionId,
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        'GetProfileQuestions' // Query name
      ],
    })

    setQuestionAddedSuccess(true)
  }

  const handleCheckboxToggle = async (question) => {
    const value = !checked[question.id]
    if(value) {
      await handleAddQuestion(question.id)
    } else {
      await handleRemoveQuestion(question.id)
    }
    setChecked(checked => ({ ...checked, [question.id]: value }))
  }

  const { data: questionSetsData } = useQuery(GET_QUESTION_SETS)

  const { loading, data, error } = useQuery(GET_PROFILE_QUESTIONS, { variables: { profileId }})
  const { profile } = data || {}
  const { questionSetsWithQuestions } = questionSetsData || {}

  useEffect(() => {
    let profileQuestionIds = {}
    if(profile?.questions && profile.questions.length > 0) {
      for(const q of profile.questions) {
        profileQuestionIds[q.id] = true
      }
    }
    console.log('INITIALIZED CHECKED - ', checked)
    setChecked(profileQuestionIds)
  }, [data])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :{error}</p>;

  // get list of unique question sets based on questions added to profile
  const questionSetIdsTemp = profile?.questions?.map((q) => Number(q.questionSetId))
  const uniqueQuestionSetIds = questionSetIdsTemp?.filter((id, index, array) => {
    return array.indexOf(id) === index
  })

  // filter available question sets to exclude sets that already have questions included in the profile
  let filteredQuestionSets = questionSetsWithQuestions
  if(questionSetsWithQuestions && uniqueQuestionSetIds?.length > 0) {
    filteredQuestionSets = questionSetsWithQuestions.filter(qs => uniqueQuestionSetIds.indexOf(qs.id) === -1)
  }

  
  const includedQuestionSets = uniqueQuestionSetIds.map(id => {
    return questionSetsWithQuestions?.find(qs => qs.id == id)
  })

  let profileQuestionButton
  if (!showAddSection) {
    profileQuestionButton = (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setShowAddSection(true);
        }}
        startIcon={<AddIcon color='primary'/>}
        size="small"
      >
        Add Questions
      </Button>
    )
  }   
  console.log('CHECKED - ', checked)
  let questionsDisplay 
  if(includedQuestionSets && includedQuestionSets.length > 0) {
    questionsDisplay = includedQuestionSets.map((qs, index) => (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{qs.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {qs.questions?.map((q) => {
              const labelId = `checkbox-list-label-${q}`;

              return (
                <ListItem key={q.id} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleCheckboxToggle(q)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked[q.id] ? true : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={q.text}
                      secondary={q.type}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      // <>
      //   <ListItem
      //     key={q.id}
      //     disablePadding
      //     secondaryAction={
      //       <IconButton aria-label="remove" onClick={() => updateQuestion(q)}>
      //         <DeleteIcon />
      //       </IconButton>
      //     }
      //   >
      //     <ListItemButton>
      //       <ListItemIcon>
      //         <AssignmentIcon />
      //       </ListItemIcon>
      //       <ListItemText primary={q.text} secondary={q.type} />
      //     </ListItemButton>
      //   </ListItem>
      //   <Divider />
 
      // </>
    ));
  } else {
    questionsDisplay = <div>No questions have been assigned to this group</div>
  }

  const profileQuestionsColumn = {
    header: <Typography variant='h5'>Profile Questions</Typography>,
    headerAction: profileQuestionButton,
    content: questionsDisplay,
  };


  let addQuestionsColumn
  if(showAddSection) {
    addQuestionsColumn = [{
      header: <Typography variant='h5'>Add Question Sets</Typography>,
      content: <AddQuestions
        profileQuestionSetIds={questionSetIds}
        addQuestionSets={handleAddQuestionSets}
        questionSets={filteredQuestionSets}
      />,
    }];
  } else {
    addQuestionsColumn = [];
  }

  return (
    <ColumnContainer columns={[profileQuestionsColumn, ...addQuestionsColumn]} />
  );
};
export default ProfileQuestion;

