import { css } from 'styled-components';

export default {
  colors: {
    // blue
    blue_shade_dark: '#305B88',
    blue_shade_default: '#3A6EA5',
    blue_shade_4: '#5D88B5',
    blue_shade_3: '#81A2C5',
    blue_shade_2: '#A5BDD6',
    blue_shade_1: '#C9D7E6',
    blue_shade_0: '#EDF1F6',
    // Neutral
    black_shade_dark: '#222223',
    black_shade_default: '#4A4A4B',
    black_shade_4: '#727273',
    black_shade_3: '#9A9A9B',
    black_shade_2: '#C2C2C3',
    black_shade_1: '#EAEAEB',
    black_shade_0: '#F1F1F2',
    // Orange
    orange_shade_dark: '#D15500',
    orange_shade_default: '#FF6700',
    orange_shade_4: '#FF822E',
    orange_shade_3: '#FF9E5C',
    orange_shade_2: '#FFB98B',
    orange_shade_1: '#FFD5B9',
    orange_shade_0: '#FFF1E7',
    // Green
    green_shade_dark: '#6BAC5F',
    green_shade_default: '#82D173',
    green_shade_4: '#98D98C',
    green_shade_3: '#AFE1A5',
    green_shade_2: '#C6EABF',
    green_shade_1: '#DCF2D8',
    green_shade_0: '#F3FAF2',
    // Red
    red_shade_dark: '#BF150D',
    red_shade_default: '#E9190F',
    red_shade_4: '#ED423A',
    red_shade_3: '#F16C66',
    red_shade_2: '#F59691',
    red_shade_1: '#F9C0BD',
    red_shade_0: '#FDEAE9',
    white: '#FFFFFF',
  },
  zIndex: {
    negative: -1000,
    default: 0,
    levelOne: 1000,
    levelTwo: 2000,
    levelThree: 3000,
    levelFour: 4000,
    levelFive: 5000,
    levelSix: 6000,
    levelSeven: 7000,
    levelEight: 8000,
    levelNine: 9000,
  },
  ease: 'cubic-bezier(0.4, 0, 0.2, 1);',
  ease_out: 'cubic-bezier(0.4, 0, 1, 1);',
  ease_in: 'cubic-bezier(0.0, 0, 0.2, 1);',
  shadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);',
};
