import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const NewQuestionSetDialog = ({
  openDialog,
  setOpenDialog,
  createNewQuestionSet,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        component: "form",
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const { questionBankName: name } = formJson || {};
          console.log("🚀 ~ onSubmit: ~ name:", name);
          await createNewQuestionSet({ name });
          setOpenDialog(false);
        },
      }}
      disableRestoreFocus
    >
      <DialogTitle>Create New Question Set</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          id="questionBankName"
          name="questionBankName"
          label="Question Set Name"
          fullWidth
          multiline
          rows={2}
          sx={{ marginTop: 2, width: "400px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewQuestionSetDialog;
