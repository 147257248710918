import React from 'react';
import { DashboardContainer } from './Dashboard.styles';
import { Chart as ChartJS, LinearScale, Tooltip, Legend, CategoryScale, PointElement, LineControllerDatasetOptions, LineElement, BarElement } from "chart.js";
import { Line, Bar } from 'react-chartjs-2';

import { Box } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement, BarElement);
const participationData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Participation',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const scoreData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Average Scores',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [50, 59, 55, 70, 83, 74, 80],
    },
  ],
  
};

const Dashboard = (props) => {
  return (
    <DashboardContainer>
      <Box col={[8, 8]}>
          <Line data={scoreData} />
          <Bar data={participationData} />
      </Box>
    </DashboardContainer>
  );
};

export default Dashboard;
