import styled, { css } from "styled-components";

export const PageContainerContainer = styled.div`
  ${(props) => {
    const { theme } = props;
    return css`
      margin-top: 80px;
      margin-left: 200px;
      padding: 30px;
    `;
  }}
`;
